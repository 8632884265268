(function () {
  'use strict';

  /*
   * @ngdoc object
   * @name home
   * @description
   *
   */
  angular
  .module('neo.home', [
    'neo.home.entitats',
    'neo.home.ajuntaments',
    'neo.home.user',
    'neo.home.seasons',
    'neo.home.teams',
    'neo.home.persons',
    'neo.home.phases',
    'neo.home.championships',
    'neo.home.facilities',
    'neo.home.postponements',
    'neo.home.matches',
    'neo.home.cancellations',
    'neo.home.referees',
    'neo.home.licenseSearch',
    'neo.home.statistics', 'neo.home.statistics.neoindi','neo.home.refereeNewInvoice',
    'neo.home.refereeNewInvoice.newline','neo.home.refereeNewInvoice.line','neo.home.jvp','neo.home.jvp2','neo.home.refereeNewInvoice.invoicegroup.invoice'
    ,'neo.home.refereeNewInvoice.invoicestrimestral','neo.home.informes','neo.home.informestecnic','neo.home.informestg','neo.home.informespublic','neo.home.comite','neo.home.sancions','neo.public.classificacio','neo.public.resultats'
  ]);
}());
